<template>
  <div class="cpt-MMC_Gimbal_FE8" :style="containerStyle">
    <div v-interact class="hd">
      <!-- <div class="title">八孔投弹器</div> -->
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">催泪弹</div>
      </div>
      <div class="close" @click="$emit('close'), $emit('showCenter', false)">{{$t('operation.Close')}}</div>
    </div>
    <div class="bd">
      <div class="rocker-wrap">
        <div class="title-box">
          <!-- <div class="title">{{$t('operation.Operation')}}</div> -->
        </div>
        <div ref="rocker" class="rocker">
          <div class="shangUp" @click="fangxiang('up')" />
          <div class="xaiUp" @click="fangxiang('down')" />
          <div class="zuoUp" @click="fangxiang('right')" />
          <div class="youUp" @click="fangxiang('left')" />
        </div>
      </div>
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">保险：</div>
          <div class="input-box">
            <el-switch v-model="safety_switch_state" size="mini" />
          </div>
        </div>
        <div class="form-item launch">
          <img src="~@/assets/images/mount/launch.png" alt="" @click="handle_laser_shine_ctrl" />
          <div class="label-box">发射</div>
          <!--<div class="input-box" @click="handle_laser_shine_ctrl">
            <el-switch v-model="laser_shine_status" size="mini"></el-switch>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_FE8 } from '../utils';
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      timer: null,
      radio: 1,
      safety_switch_state: false,
      laser_shine_status: false
    };
  },
  mounted() {
    this.$emit('showCenter', true);
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  methods: {
    handle_laser_shine_ctrl(value) {
      if (!this.safety_switch_state) {
        this.$message.warning('未打开保险');
        return (this.laser_shine_status = false);
      } else {
        // if (this.laser_shine_status) {
        const buff = MMC_Gimbal_FE8.laser_shine_ctrl(1);
        this.commit_directive(buff);
        this.$message.success('发射成功');
        // setTimeout(() => {
        //   this.laser_shine_status = false;
        // }, 500);
        // }
      }
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit('directive', buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case 'up':
          buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(1);
          this.commit_directive(buffer);
          break;
        case 'right':
          // buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          // this.commit_directive(buffer);
          // setTimeout(() => {
          //   buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(-1);
          //   this.commit_directive(buffer);
          // }, 50);
          break;
        case 'left':
          // buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          // this.commit_directive(buffer);
          // setTimeout(() => {
          //   buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(1);
          //   this.commit_directive(buffer);
          // }, 50);
          break;
        case 'down':
          buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(-1);
          this.commit_directive(buffer);
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(0);
        this.commit_directive(buffer);
      }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case 'up':
              buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(1);
              this.commit_directive(buffer);
              break;
            case 'right':
              // buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
              // this.commit_directive(buffer);
              // setTimeout(() => {
              //   buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(-1);
              //   this.commit_directive(buffer);
              // }, 50);
              break;
            case 'left':
              // buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
              // this.commit_directive(buffer);
              // setTimeout(() => {
              //   buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(1);
              //   this.commit_directive(buffer);
              // }, 50);
              break;
            case 'down':
              buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(-1);
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_FE8.gimbal_pitch_ctrl(0);
          this.commit_directive(buffer);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_FE8 {
  position: absolute;
  // bottom: 0;
  right: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35), inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 470px;
  height: 290px;

  box-sizing: border-box;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .bd {
    padding: 10px 20px;
    display: flex;
    // justify-content: space-between;
    overflow-y: auto;
    height: 230px;
    overflow-x: hidden;
    .form-wrap {
      margin-left: 124px;
      // min-width: 300px;
      // flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          // width: 75px;
        }
        .input-box {
          display: flex;
          align-items: center;
          .icon-box {
            [class^='el-icon-'] {
              font-size: 30px;
              color: #dce9ff;
            }
          }
        }
      }
      .launch {
        margin-top: 35px;
        margin-left: 22px;
        display: flex;
        flex-direction: column;
      }
    }
    .rocker-wrap {
      // flex: 1;
      .title-box {
        .title {
          color: #cbd0eb;
        }
      }
      .rocker {
        // margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        // margin: 0 auto;
        background: center url('~@/assets/images/accident/caozuo.png') no-repeat;
        background-size: 100% 100%;
        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
</style>
